<template>
  <div class="tc-userVideo">
    <div class="userVide-layout">
      <div class="title">
        <p>欢迎使用,视频问诊系统</p>
      </div>
      <div class="video_header big">
        <p>
          2020年5月9日 （星期六） --- 视频问诊已经进行：7 分 35 秒
          <el-divider direction="vertical"></el-divider>
          <span>
            当前房间号：{{roomId}}
            <el-divider direction="vertical"></el-divider>
            当前视频状态：{{roomStatus}}
            <el-divider direction="vertical"></el-divider>
            <!-- {{selectedStreamStatus}} -->
          </span>
          <span style="float:right;padding-right: 20px">
            <el-button type="primary" v-on:click="handlePublish" size="small">开启视频</el-button>
            <el-button type="primary" v-on:click="handleLeaveRoom" size="small">关闭视频</el-button>
            <!-- <button v-on:click="handlePublish">发布</button>
          <button v-on:click="handlePublishScreen">屏幕共享</button>
          <button v-on:click="handleUnpublish">取消发布/屏幕共享</button>
          <button v-on:click="handleSubscribe">订阅</button>
            <button v-on:click="handleUnsubscribe">取消订阅</button>-->
            <!-- <button v-on:click="handleLeaveRoom">离开房间</button> -->
          </span>
        </p>
      </div>
      <div class="msgInfo" v-if="isJoinedRoom">
        <p v-if="adminName!=''">您 正在与 {{adminName}} 视频通话中</p>
        <p v-else>请等待,医生赶来的路上</p>
      </div>
      <!-- 视频窗口 -->
      <div class="videoWindow">
        <!-- 他 -->
        <div
          class="stream-container"
          v-for="stream in remoteStreams"
          :key="stream.sid"
          v-on:click="handleSelectStream(stream)"
        >
          <stream-info v-bind:client="client" v-bind:stream="stream"></stream-info>
          <div class="video-container" :id="stream.sid"></div>
        </div>
        <!-- 我 -->
        <div
          class="stream-container viceVideoWindow"
          v-for="stream in localStreams"
          :key="stream.sid"
          v-on:click="handleSelectStream(stream)"
        >
          <stream-info v-bind:client="client" v-bind:stream="stream"></stream-info>
          <div class="video-container" :id="stream.sid"></div>
        </div>
      </div>
      <div class="mini_btn">
        <el-button type="primary" v-on:click="handlePublish" v-show="!isJoinedRoom">开启视频</el-button>
        <el-button type="primary" v-on:click="handleLeaveRoom" v-show="isJoinedRoom">关闭视频</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import sdk, { Client } from "urtc-sdk";
import { ujoinvideo } from "@/api/video.js";
const AppId = "urtc-4hwfhylz";
const AppKey = "8eb0870aa5762951bfaff22f262b1bd9";
// 此处使用固定的房间号的随机的用户ID，请自行替换
const RoomId = "001";
const UserId = localStorage.getItem("userName");
export default {
  data() {
    return {
      msg: "",
      code: 0,
      roomId: RoomId,
      userId: UserId,
      isJoinedRoom: false,
      selectedStream: null,
      localStreams: [],
      remoteStreams: [],
      adminName: ""
    };
  },
  // 计算属性
  computed: {
    // 是否已加入
    roomStatus: function() {
      return this.isJoinedRoom ? "已开启" : "未开启";
    },
    // 是否选择
    selectedStreamStatus: function() {
      return this.selectedStream ? this.selectedStream.sid : "未选择";
    }
  },
  // 渲染完成前调用
  created: function() {
    // 判断是否存在 appid 和 AppKey
    if (!AppId || !AppKey) {
      alert("请先设置 AppId 和 AppKey");
      return;
    }
    // 判断是否存在 房间号 和 用户id
    if (!RoomId) {
      alert("请先设置 RoomId");
      return;
    }
    if (!UserId) {
      alert("请先设置 UserId");
    }
  },
  // 渲染完成后调用
  mounted: function() {
    // 创建 token
    const token = sdk.generateToken(AppId, AppKey, RoomId, UserId);
    this.client = new Client(AppId, token);
    //监听-本地流已发布，可在页面中播放该流
    this.client.on("stream-published", localStream => {
      this.selectedStream = localStream;
      console.info("stream-published: ", localStream);
      const { localStreams } = this;
      localStreams.push(localStream);
      this.$nextTick(() => {
        this.client.play({
          streamId: localStream.sid,
          container: localStream.sid
        });
      });
    });
    // 监听-有其他用户加入房间，自动订阅
    this.client.on("stream-added", remoteStream => {
      console.info("用户加入-stream-added: ", remoteStream);
      const { remoteStreams } = this;
      this.adminName = remoteStream.uid;
      remoteStreams.push(remoteStream);
      //console.log("用户组", remoteStreams);
      // 自动订阅
      this.client.subscribe(remoteStream.sid, err => {
        console.error("自动订阅失败：", err);
      });
    });
    // 监听-远端流已订阅，可在页面中播放该流
    this.client.on("stream-subscribed", remoteStream => {
      console.info("stream-subscribed: ", remoteStream);
      const { remoteStreams } = this;
      const idx = remoteStreams.findIndex(
        item => item.sid === remoteStream.sid
      );
      if (idx >= 0) {
        remoteStreams.splice(idx, 1, remoteStream);
      }
      this.$nextTick(() => {
        this.client.play({
          streamId: remoteStream.sid,
          container: remoteStream.sid
        });
      });
    });
    // 监听-有其他用户取消发布了一条流
    this.client.on("stream-removed", remoteStream => {
      console.info("stream-removed: ", remoteStream);
      const { remoteStreams } = this;
      const idx = remoteStreams.findIndex(
        item => item.sid === remoteStream.sid
      );
      if (idx >= 0) {
        remoteStreams.splice(idx, 1);
      }
    });
    // 监听-断线重连
    this.client.on("connection-state-change", ({ previous, current }) => {
      //console.log(`连接状态 ${previous} -> ${current}`);
    });
    // 监听- 发布/订阅流断开时，会自动重连，发布流会被重新发布，订阅流会被重新订阅，完成后会触发此事件
    this.client.on("stream-reconnected", ({ previous, current }) => {
      //console.log(`流已断开重连`);
      const isLocalStream = previous.type === "publish";
      const streams = isLocalStream ? this.localStreams : this.remoteStreams;
      const idx = streams.findIndex(item => item.sid === previous.sid);
      if (idx >= 0) {
        // 更新流的信息
        streams.splice(idx, 1, current);
      }
      this.$nextTick(() => {
        this.client.play({
          streamId: current.sid,
          container: current.sid
        });
      });
    });
    // 当浏览器窗口关闭或者刷新时,会触发beforeunload事件
    window.addEventListener("beforeunload", this.handleLeaveRoom);
  },
  // 实例销毁之前调用
  beforeDestroy: function() {
    console.info("component will destroy");
    window.removeEventListener("beforeunload", this.handleLeaveRoom);
    this.handleLeaveRoom();
  },
  // 离开页面的时候调用
  destroyed: function() {
    this.isComponentDestroyed = true;
  },
  // 方法域
  methods: {
    // 点击加入房间
    handleJoinRoom: function() {
      const { roomId, userId, isJoinedRoom } = this;
      if (isJoinedRoom) {
        alert("已经加入了房间");
        return;
      }
      if (!roomId) {
        alert("请先填写房间号");
        return;
      }
      this.client.joinRoom(
        roomId,
        userId,
        () => {
          this.isJoinedRoom = true;
          console.info("加入房间成功");
          this.client.publish({
            audio: true,
            video: true
          });
        },
        err => {
          console.error("加入房间失败： ", err);
        }
      );
    },
    // 点击发布
    handlePublish: function() {
      //console.log("所有用户信息",this.client.getRemoteStreams());
      ujoinvideo().then(res => {
        // console.log("res", res);

        if (res.code == 400400) {
          this.$alert(res.msg, "通知", {
            confirmButtonText: "确定"
          });
        } else {
          console.log("开启了");
          this.handleJoinRoom();
        }
      });

      // this.client.publish(err => {
      //   console.error(
      //     `发布失败：错误码 - ${err.name}，错误信息 - ${err.message}`
      //   );
      // });
    },
    // 屏幕共享
    handlePublishScreen: function() {
      this.client.publish({ audio: false, video: false, screen: true }, err => {
        console.error(
          `发布失败：错误码 - ${err.name}，错误信息 - ${err.message}`
        );
      });
    },
    // 取消发布本地流
    handleUnpublish: function() {
      const { selectedStream } = this;
      //console.log(this.client.getMediaStream(UserId));
      if (!selectedStream) {
        alert("还没有开启视频");
        return;
      }
      this.client.unpublish(
        selectedStream.sid,
        stream => {
          console.info("取消发布本地流成功：", stream);
          this.isJoinedRoom = false;
          const { localStreams } = this;
          const idx = localStreams.findIndex(item => item.sid === stream.sid);
          if (idx >= 0) {
            localStreams.splice(idx, 1);
          }
          this.selectedStream = null;
        },
        err => {
          console.error("取消发布本地流失败：", err);
        }
      );
    },
    // 订阅远端流
    handleSubscribe: function() {
      const { selectedStream } = this;
      if (!selectedStream) {
        alert("未选择需要订阅的远端流");
        return;
      }
      this.client.subscribe(selectedStream.sid, err => {
        console.error("订阅失败：", err);
      });
    },
    // 取消订阅远端流
    handleUnsubscribe: function() {
      const { selectedStream } = this;
      if (!selectedStream) {
        alert("未选择需要取消订阅的远端流");
        return;
      }
      this.client.unsubscribe(
        selectedStream.sid,
        stream => {
          console.info("取消订阅成功：", stream);
          const { remoteStreams } = this;
          const idx = remoteStreams.findIndex(item => item.sid === stream.sid);
          if (idx >= 0) {
            remoteStreams.splice(idx, 1, stream);
          }
        },
        err => {
          console.error("订阅失败：", err);
        }
      );
    },
    // 离开房间
    handleLeaveRoom: function() {
      const { isJoinedRoom } = this;
      if (!isJoinedRoom) {
        return;
      }
      this.client.leaveRoom(
        () => {
          console.info("离开房间成功");
          this.selectedStream = null;
          this.localStreams = [];
          this.remoteStreams = [];
          this.isJoinedRoom = false;
          this.adminName = "";
        },
        err => {
          console.error("离开房间失败：", err);
        }
      );
    },
    // 当前流信息
    handleSelectStream: function(stream) {
      //console.log("select stream: ", stream);
      this.selectedStream = stream;
    },
    // 用户加入
    userjoin() {
      ujoinvideo()
        .then(res => {
          console.log(res);
          this.msg = res.msg;
          this.code = res.code;
        })
        .then(res => {
          console.log(this.code);
        });
    }
  }
};
</script>


<style lang="less">
.tc-userVideo {
  height: 100%;
  .userVide-layout {
    max-width: 1400px;
    min-width: 320px;
    height: 100%;
    margin: 0 auto;
    // 标题
    .title {
      font-size: 25px;
      padding: 10px 0;
      text-align: center;
    }
    // 头部
    .video_header {
      margin-top: 10px;
      width: 100%;
      height: 50px;
      background: rgb(217, 242, 247);
      // margin-top: 20px;
      p {
        color: rgb(65, 92, 72);
        line-height: 50px;
        padding-left: 20px;
      }
    }
    // 信息
    .msgInfo {
      height: 30px;
      line-height: 30px;
      font-size: 20px;
      color: rgb(65, 92, 72);
      padding: 5px 0;
    }
    // 视频窗口
    .videoWindow {
      position: relative;
      margin: 0 auto;
      width: 80%;
      height: calc(~"100vh - 300px");
      background: rgb(150, 145, 145);
      // 画中画
      .viceVideoWindow {
        position: absolute;
        bottom: 20px;
        right: 20px;
        width: 150px;
        height: 150px;
        background-size: 100% 100%;
        border: 3px lavender solid;
        background: black;
      }
    }
    .stream-container {
      display: inline-block;
      margin: 2px;
      width: 100%;
      height: 100%;
      text-align: left;
      white-space: nowrap;
      cursor: pointer;
    }
    .stream-container .video-container {
      position: relative;
      width: 100%;
      height: 100%;
    }
    .mini_btn {
      display: none;
    }
  }
}

@media screen and (max-width: 768px) {
  .tc-userVideo {
    .userVide-layout {
      max-width: 812px;
      min-width: 320px;
      margin: 0 auto;
      // 头部
      .big {
        display: none;
      }
      .video_header {
        margin-top: 10px;
        width: 100%;
        height: 50px;
        background: rgb(217, 242, 247);
        // margin-top: 20px;
        p {
          color: rgb(65, 92, 72);
          line-height: 50px;
          padding-left: 20px;
        }
      }
      .msgInfo {
        text-align: center;
        height: 30px;
        line-height: 30px;
        font-size: 16px;
        color: rgb(65, 92, 72);
        padding: 2px 0;
      }
      // 视频窗口
      .videoWindow {
        position: relative;
        width: 100%;
        height: 400px;
        background: rgb(150, 145, 145);
        // 画中画
        .viceVideoWindow {
          position: absolute;
          bottom: 5px;
          right: 5px;
          width: 100px;
          height: 120px;
          background-size: 100% 100%;
          border: 3px lavender solid;
          background: black;
        }
      }
      .stream-container {
        display: inline-block;
        margin: 2px;
        width: 100%;
        text-align: left;
        white-space: nowrap;
        cursor: pointer;
      }
      .stream-container .video-container {
        position: relative;
        width: 100%;
        height: 100%;
      }
      .mini_btn {
        margin-top: 20px;
        display: flex;
        justify-content: center;
      }
    }
  }
}
</style>